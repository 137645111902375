import React from 'react'

import TermsPages from '../partials/Terms/About'
import { dataTermsCreditCard } from '../data/terms/terms-credit-card'

const TermsCardPage = () => (
  <TermsPages
    data={dataTermsCreditCard}
    linkCanonical="termos-de-uso-cartao-de-credito"
  />
)
export default TermsCardPage
