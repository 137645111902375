import { TermsProps } from '../../@interface/terms'

export const dataTermsCreditCard: TermsProps = {
  title: 'Termos de Uso do Cartão de Crédito Cora',
  subtitle: 'de uso do Cartão de Crédito Cora',
  update: '5 de junho de 2024',
  about: {
    title: 'Termos de Uso do Cartão',
    introduction: `
      Este documento (“Termos de Uso do Cartão”) apresenta as condições de uso dos cartões de crédito (“Cartões de Crédito CORA”, “Cartões” ou, individualmente, “Cartão”) disponibilizados pela Cora Sociedade de Crédito, Financiamento e Investimento S.A. - “CORA SCFI”., sociedade com sede na Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação, São Paulo - SP, CEP 01307-002, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Economia (“CNPJ/ME”) sob o nº37.880.206/0001-63 (“CORA” ou “Nós”).
      <br/>
      <b>
        Você deve ler atentamente e por inteiro estes Termos de Uso do Cartão, pois eles descrevem os termos e condições aplicáveis às empresas que pretendem ter ou tenham acesso aos Cartões de Crédito CORA (“Empresas Selecionadas”).
      </b>
      <br/>
      <b>
        A utilização dos Cartões de Crédito CORA implica sua total concordância com as disposições destes Termos de Uso do Cartão. Caso não concorde com qualquer uma dessas disposições,
        não utilize os Cartões.
      </b>
      <br/>
      Estes Termos de Uso do Cartão são parte integral e inseparável dos Termos e Condições Gerais de Uso da CORA, disponível em <a href="/termos-uso">cora.com.br/termos-uso</a>, e da Política de Privacidade da CORA, disponível em <a href="/politica-privacidade">cora.com.br/politica-privacidade</a>, de forma que a aceitação destes Termos de Uso do Cartão   implica, necessariamente, a aceitação dos Termos e Condições Gerais de Uso e da Política de Privacidade da CORA. Por este motivo, você deverá também ler atentamente esses instrumentos.
      <br/>
      <b>
        Estes Termos de Uso do Cartão poderão ser alterados pela CORA a qualquer tempo. Você será previamente comunicado a respeito da realização de alterações e, caso não concorde com qualquer uma delas, deverá deixar de utilizar o Cartão, bem como poderá requisitar seu cancelamento.
      </b>
    `,
  },
  section: [
    {
      list: [
        {
          head: 'Definições Importantes',
          about: `Nesta seção, apresentamos o significado de alguns termos que serão utilizados ao longo destes Termos de Uso do Cartão.`,
          intro: `Nestes Termos de Uso do Cartão, os termos abaixo, quando iniciados em letras maiúsculas, deverão ter o seguinte significado:`,
          items: [
            {
              item: `<b>Aplicações</b>: são aplicações de internet (como, por exemplo, site ou aplicativo mobile) ou outras ferramentas disponibilizadas pela CORA para que você possa gerenciar a Conta CORA de sua empresa e utilizar os Serviços.`,
            },
            {
              item: `<b>Aplicativos</b>: são as Aplicações específicas para uso em dispositivos móveis;
            `,
            },
            {
              item: `<b>Aplicativos de Terceiros</b>: outros serviços financeiros e contas de pagamento fornecidos por terceiros, e que possuem integração com as Aplicações da Cora.;
            `,
            },
            {
              item: `<b>Bandeira</b>: é a Visa do Brasil Empreendimentos Ltda., instituidora do arranjo de pagamento que viabiliza o uso do Cartão nos Estabelecimentos e do qual a CORA participa como emissora do Cartão;`,
            },
            {
              item: `<b>CET:</b> é o custo efetivo total das operações de financiamento vinculadas ao uso do Cartão, calculado e informado nos termos da regulamentação em vigor;`,
            },
            {
              item: `<b>Disputa:</b> é o processo de contestação de transações que foram realizadas no cartão de débito ou crédito, em estabelecimento virtual ou físico, e abrange as 3 modalidades a seguir:`,
              child: [
                {
                  item: `<b>Fraude</b>: hipótese em que a Sua Empresa afirma não ter realizado e/ou autorizado a transação;`,
                },
                {
                  item: `<b>Erro</b>: existência comprovada de falha na autorização ou processamento da transação;`,
                },
                {
                  item: `<b>Desacordo comercial</b>: hipótese em que houve o descumprimento do acordo firmado no ato da compra entre a Sua Empresa e o estabelecimento beneficiário da transação.`,
                },
              ],
            },
            {
              item: `<b>Canais de Comunicação:</b> são os canais oficiais para comunicação entre a CORA e você, para temas referentes ao uso dos Serviços aqui apresentados:<br/>
              E-mail de atendimento: <a href="mailto:meajuda@cora.com.br">meajuda@cora.com.br</a>
              <br/>
              Whatsapp oficial: <a href="https://api.whatsapp.com/send?phone=5511933058198" target="_blank">(11) 93305-8198</a>.
              <br/>
              Chat do aplicativo, para contestação de transações e dúvidas sobre os nossos serviços.
              <br/>
              O atendimento nos canais acima é disponibilizado 24h por dia, 7 dias por semana.
              <br/>
              O tempo de resposta para contatos via e-mail é de até 3 (três) dias úteis, assim, dentro do horário de atendimento recomendamos o contato pelo chat, Whatsapp oficial ou pelos telefones abaixo:
              <br/>
              Atendimento telefônico: <a href="tel:1150395715">(11) 5039-5715</a> e <a href="tel:08005912350">0800 591 2350</a>, todos os dias, das 9h às 17h (horário de Brasília), para pedidos de cancelamento e reclamações.
              <br/>
              Em caso de perda ou roubo do celular ou cartão Cora o atendimento é 24h.`,
            },
            {
              item: `<b>Cartão de Crédito CORA ou Cartão:</b> é um cartão físico e/ou virtual, com funcionalidade pós-paga e pré-paga, emitido pela CORA e que, a exclusivo critério da CORA, será oferecido às Empresas Selecionadas, possibilitando a realização de compras de bens e/ou serviços até o Limite de Crédito contratado, bem como retirada de recursos (saques), sendo que os valores das referidas transações deverão ser pagos pela Empresa Selecionada até a data de vencimento da Fatura;`,
            },
            {
              item: `<b>Conta CORA:</b> conta de pagamento digital pré-paga gerida pela CORA, de titularidade da Empresa Selecionada e movimentada por Aplicativo ou pelo Cartão na modalidade pré-paga com a finalidade de realizar pagamentos, de acordo com as regras destes Termos de Uso do Cartão e dos Termos e Condições Gerais de Uso da CORA;`,
            },
            {
              item: `<b>Devedor Solidário</b>: sócio ou acionista da Empresa Selecionada, seja pessoa física ou jurídica, que, neste ato, declara ser interveniente anuente deste Documento e igualmente responsável a cumprir as obrigações presentes no Cartão de Crédito CORA. O devedor solidário responderá solidariamente por todas as obrigações assumidas pela Empresa Selecionada neste Contrato ou que dele decorram;`,
            },
            {
              item: `<b>Empresa Selecionada</b>: pessoa jurídica constituída no Brasil, devidamente inscrita na Receita Federal do Brasil, titular da Conta CORA, e selecionada, à critério da CORA, como elegível para obtenção do Cartão de Crédito CORA;`,
            },
            {
              item: `<b>Estabelecimento:</b> qualquer fornecedor de produtos e/ou serviços situado no Brasil ou no exterior, que esteja habilitado a aceitar pagamentos com o Cartão de Crédito CORA, em lojas físicas ou por meio da internet;`,
            },
            {
              item: `<b>Fatura:</b> documento eletrônico disponibilizado mensalmente pela CORA, contendo o detalhamento da utilização do Cartão de Crédito CORA em determinado período e demais informações exigidas na regulamentação em vigor;`,
            },
            {
              item: `<b>Informações:</b> são todos os dados, informações, esclarecimentos e documentos relativos à Empresa Selecionada e fornecidos por Você ou por outros Usuários cadastrados, seja no momento do cadastro junto à CORA ou da contratação do Cartão;`,
            },
            {
              item: `<b>Limite de Crédito:</b> é o valor máximo estabelecido pela CORA, em moeda corrente nacional, que pode ser movimentado ao longo de um determinado período pela Empresa Selecionada por meio de um Cartão de Crédito CORA, valor esse que, quando aceito pela Empresa Selecionada, poderá ser ajustado por meio dos Canais de Comunicação;`,
            },
            {
              item: `<b>Pagamento Mínimo:</b> valor mínimo indicado na Fatura que deve ser pago pela Empresa Selecionada, enquanto portadora do Cartão de Crédito CORA, até a data de vencimento da Fatura, para que a Empresa Selecionada não fique em atraso. Este valor mínimo é estabelecido pela CORA, e poderá ser alterado nos termos previstos em legislação aplicável;`,
            },
            {
              item: `<b>Parceira:</b> empresas terceiras contratadas pela CORA que auxiliam ou viabilizam a prestação de serviços relacionados ao Cartão, ou, ainda, empresas terceiras (integrantes do grupo econômico CORA ou não) que poderão oferecer a Empresa Selecionada produtos e serviços relacionados ou não à CORA;`,
            },
            {
              item: `<b>Senha:</b> palavra-passe inserida por meio de digitação ou outro método de validação pessoal para utilização do Cartão;`,
            },
            {
              item: `<b>Tarifas:</b> valores cobrados pela CORA ou por Parceiras, quando aplicável;`,
            },
            {
              item: `<b>Usuário:</b> pessoa física representante de Sua Empresa, com poderes atribuídos pelos documentos societários de Sua Empresa, procuração;`,
            },
            {
              item: `<b>Você:</b> usuário responsável pelo cadastro da Empresa Selecionada, dos Devedores Solidários e dos Usuários para acesso aos Serviços;`,
            },
          ],
        },
        {
          head: 'Contratação do Cartão de Crédito CORA',
          about:
            'São as condições aplicáveis ao cadastro da Sua Empresa e à contratação do Cartão de Crédito CORA',
          intro: '',
          items: [
            {
              item:
                'Sua Empresa poderá utilizar seu Cartão no território nacional ou no exterior, de forma a realizar a compra de bens e/ou serviços nos Estabelecimentos, observado o limite disponível no seu Cartão e o período de validade impresso no seu Cartão.',
            },
            {
              item:
                'Restrição preventiva. Fica desde já estabelecido que a CORA, visando à segurança de Sua Empresa e para evitar o uso fraudulento do Cartão, poderá eventualmente limitar ou restringir o uso do Cartão.',
            },
            {
              item: `Comunicação com a Sua Empresa. Sua Empresa será contactada pela CORA através dos Canais de Comunicação oficiais, sendo, nesse ato, informada sobre sua elegibilidade para contratação do Cartão de Crédito CORA e sobre as condições aplicáveis.`,
            },
            {
              item: `Contratação do Cartão. No ato de contratação do Cartão de Crédito CORA, a Empresa Selecionada e os Devedores Solidários deverão estar adequadamente representados por Você, nos termos dos documentos societários da Empresa Selecionada, tais como contrato social, estatuto social, ata de eleição de diretoria e outros, ou procuração.`,
            },
            {
              item: `Informações cadastrais. Para efetuar o cadastro da Empresa Selecionada e dos Devedores Solidários para disponibilização do Cartão de Crédito CORA, a CORA poderá utilizar as Informações da Empresa Selecionada e dos Devedores Solidários e dados fornecidos no âmbito de outros serviços prestados pela CORA, e também coletar informações adicionais junto a Parceiras, sem prejuízo de a CORA, a qualquer tempo, solicitar documentos e informações adicionais diretamente a Você.`,
              child: [
                {
                  item: `Você, enquanto representante da Empresa Selecionada e dos Devedores Solidários, é o único e exclusivo responsável pelas Informações da Empresa Selecionada fornecidas no momento do cadastro, inclusive àquelas relativas aos Devedores Solidários e Usuários cadastrados, declarando estar devidamente constituído como representante e que as Informações são corretas, completas e verdadeiras, e devendo manter as Informações sempre atualizadas.`,
                },
                {
                  item: `Você autoriza a CORA a verificar, por si ou através de suas Parceiras, a qualquer momento, a veracidade das Informações, e solicitar, a exclusivo critério da CORA, esclarecimentos e documentação suporte que julgar necessários para a devida comprovação das informações prestadas e para a validação do cadastro, estando ciente de que a CORA poderá, inclusive, recusar-se a validar qualquer cadastro ao seu critério exclusivo.`,
                },
                {
                  item: `Em caso de fornecimento de informações falsas, incompletas, equivocadas, errôneas ou enganosas, o cadastro da Sua Empresa poderá ser automaticamente suspenso, até que se regularize a situação, ou excluído, efetuando a CORA, nesse caso, o imediato cancelamento do Cartão de Crédito CORA.`,
                },
              ],
            },
            {
              item: `Demonstração de governança da Sua Empresa. A CORA se reserva no direito de solicitar documentos comprobatórios e constitutivos da Empresa, a qualquer momento.`,
            },
            {
              item: `Cadastro de Usuários autorizados. Você é responsável pelo cadastro de Usuários, os quais terão Senhas próprias de caráter confidencial, exclusivo e intransferível para acesso às Aplicações.`,
              child: [
                {
                  item: `Os Usuários cadastrados por Você deverão estar autorizados pelas regras de governança da Sua Empresa a utilizar todos os Serviços, incluindo movimentar recursos contratar e assumir obrigações em nome da Empresa, sendo que a observância desta obrigação é de Sua responsabilidade, não tendo a CORA qualquer responsabilidade por quaisquer prejuízos causados à Sua Empresa pelo mau uso dos Serviços pelo Usuário.`,
                },
              ],
            },
            {
              item: `Emissão de Cartões adicionais. Os Usuários cadastrados por Você com o perfil de “sócio” poderão solicitar o Cartão de Crédito e ajustar os Limites de Crédito de acordo com o presente Termos de Uso do Cartão.`,
            },
          ],
        },
        {
          head: 'Uso do Cartão de Crédito CORA',
          about:
            'São as disposições específicas para o uso do Cartão de Crédito CORA, incluindo informações sobre Limite de Crédito, parcelamentos, atrasos no pagamento, Faturas, Disputas e outros.',
          intro: '',
          items: [
            {
              item: `Função Crédito. O Cartão poderá ser utilizado, no Brasil ou no exterior, para compra de bens e/ou serviços nos Estabelecimentos, observados o Limite de Crédito contratado, o período de validade impresso no Cartão e as tarifas e taxas inerentes à movimentação.`,
              child: [
                {
                  item: `A aprovação e a utilização do Cartão, bem como a disponibilização do Limite de Crédito, dependerão de prévia análise pela CORA.`,
                },
              ],
            },
            {
              item: `Compras à vista ou parceladas. O Cartão da Sua Empresa poderá ser utilizado para compras à vista ou parceladas, de acordo com as políticas adotadas pelos Estabelecimentos, enquanto únicos e exclusivos responsáveis pela definição de regras e informações relacionadas aos parcelamentos.`,
              child: [
                {
                  item: `Ao efetuar compras parceladas no Cartão de Crédito CORA, Você tem conhecimento de que o valor total da compra será debitado do Limite de Crédito, e que o valor de cada parcela será lançado para pagamento nas Faturas vigentes nos respectivos vencimentos. O restabelecimento do Limite de Crédito ocorrerá conforme o reconhecimento do pagamento de cada parcela.`,
                },
              ],
            },
            {
              item: `Cancelamento de compra. Em caso de cancelamento de qualquer compra definitiva ou pré-autorizada no Cartão de Crédito CORA, Você deverá obter, no ato, o comprovante do cancelamento junto ao Estabelecimento.`,
              child: [
                {
                  item: `O Estabelecimento deverá providenciar junto a CORA, quando aplicável, o não lançamento de uma transação na Fatura ou estorno caso o pagamento já tenha sido realizado.`,
                },
                {
                  item: `Em caso de compras usando a Função Crédito realizadas sem a presença do cartão (compras no ambiente virtual), Você poderá exercer o seu direito de arrependimento no prazo de 7 dias. Nesse caso, e conforme previsto no Código de Defesa do Consumidor, Você deverá solicitar o cancelamento da compra diretamente ao Estabelecimento.`,
                },
                {
                  item: `Limites para pagamentos sem contato (contactless). As compras acima de R$200,00 (duzentos reais) deverão ser realizadas inserindo a senha do cartão.`,
                },
                {
                  item: `Para que haja o estorno de um valor ou não lançamento de uma transação na Fatura, cujo pagamento na Função Crédito já tenha sido realizado, o Estabelecimento será responsável em fazer a comunicação de tal fato à CORA.`,
                },
              ],
            },
            {
              item: `Limite de Crédito. Você poderá utilizar o Cartão de Crédito CORA da Sua Empresa até o valor do Limite de Crédito contratado.`,
              child: [
                {
                  item: `O Limite de Crédito será comprometido pelo valor total de:
                    <br/>
                    (i) gastos e despesas decorrentes do uso do Cartão, inclusive compras parceladas;
                    <br/>
                    (ii) pré-autorizações de operações com o Cartão na Função Crédito;
                    <br/>
                    (iii) juros, tributos/impostos e demais despesas, de acordo com estes Termos;
                    <br/>
                    (iv) financiamentos contratados, inclusive para pagamento parcelado parcelamento de Faturas;
                    <br/>
                    (v) renegociação das condições de pagamento do Cartão na Função Crédito; e
                    <br/>
                    (vi) outros pagamentos devidos à CORA, conforme disposto nestes Termos de Uso do Cartão.`,
                },
                {
                  item: `O Limite de Crédito da Sua Empresa está disponível para consulta na Fatura e no extrato da Conta CORA, através do Aplicativo e dos Canais de Comunicação.`,
                },
                {
                  item: `Sua Empresa poderá reduzir o Limite de Crédito por meio do aplicativo, através de ajuste na barra de Limite de Crédito. A CORA poderá, ainda, reduzir o Limite de Crédito a seu próprio critério mediante envio de notificação prévia a Você, nos termos da legislação aplicável.`,
                },
                {
                  item: `O aumento do Limite de Crédito poderá ser solicitado por Você por meio dos Canais de Comunicação. Uma vez solicitado, Sua empresa passará por uma nova análise de crédito. O uso do Cartão, após as comunicações de aumento, implicará a expressa concordância da Sua Empresa com o novo Limite de Crédito.`,
                },
                {
                  item: `É Sua obrigação sempre acompanhar o seu Limite de Crédito. O Emissor negará a utilização do seu Cartão na Função Crédito, caso não haja Limite de Crédito disponível para determinada transação.`,
                },
                {
                  item: `O Limite de Crédito será automaticamente recomposto em até 30 (trinta) minutos após o pagamento da Fatura.`,
                },
              ],
            },
            {
              item: `Fatura do Cartão. A Fatura será disponibilizada mensalmente nas Aplicações e nela constarão as seguintes informações, divididas em três grupos (i) área de destaque; (ii) alternativas de pagamento; e (iii) informações complementares:`,
              child: [
                {
                  item: `A área de destaque conterá as seguintes informações:<br/>
                  I - valor total da fatura;<br/>
                  II - data de vencimento da fatura do período vigente; e<br/>
                  III - limite de crédito total.
                  `,
                },
                {
                  item: `A área de alternativas de pagamento conterá as seguintes informações:<br/>
                  I - o valor para Pagamento Mínimo e o valor dos encargos a serem cobrados no mês seguinte no caso de se optar pelo Pagamento Mínimo, o qual poderá ser verificado no fluxo de pagamento dentro do Aplicativo da Conta CORA;<br/>
                  II - opções de financiamento do saldo devedor da fatura; e<br/>
                  III - taxas efetivas de juros mensal e anual, encargos, multa, juros moratórios e o Custo Efetivo Total (CET) relativos às operações de crédito passíveis de contratação de que trata o item II.
                  `,
                },
                {
                  item: `A área de informações complementares conterá as seguintes informações:<br/>
                  I - lançamentos realizados na conta de pagamento, por evento, inclusive quando parcelados;<br/>
                  II - identificação das operações de crédito contratadas e respectivos valores;<br/>
                  III - valor de cada parcela das operações de crédito contratadas, incluindo o número da parcela em relação ao total, em caso de cobrança parcelada;<br/>
                  IV - valores relativos aos juros e encargos financeiros cobrados no período vigente, segregados de acordo com os tipos de operações de crédito contratadas;<br/>
                  V - valor total de juros e encargos financeiros cobrados referentes às operações de crédito contratadas;<br/>
                  VI - identificação das tarifas cobradas, de acordo com as regras previstas na regulamentação vigente, incluindo o número da parcela em relação ao total, em caso de cobrança parcelada;<br/>
                  VII - data de encerramento dos lançamentos na fatura do período seguinte;<br/>
                  VIII - identificação dos usuários finais beneficiários de pagamento ou transferência, inclusive nas situações em que o serviço de pagamento envolver instituições participantes de diferentes arranjos de pagamento;<br/>
                  IX - limites individuais para cada tipo de operação, incluindo os limites de crédito utilizados e disponíveis; e<br/>
                  X - saldo total consolidado das obrigações futuras, inclusive das relativas a parcelamentos de compras, de operações de crédito e de tarifas.
                  `,
                },
                {
                  item: `Você deverá conferir todas as despesas lançadas em sua Fatura, a qual poderá ser verificada no Aplicativo da Conta CORA e, caso discorde de algum lançamento, deverá entrar em contato conosco em até 60 dias corridos contados da data da compra para dar início ao processo de contestação. O não questionamento de quaisquer lançamentos indicados na Fatura dentro desse prazo implicará o reconhecimento e aceitação de todos eles pela Sua Empresa.`,
                  child: [
                    {
                      item: `A disputa seguirá as regras estabelecidas pela Bandeira, pela Cora e/ou pelas Parceiras, de forma que apenas será possível abrir o processo de disputa mediante análise. Quando necessário, para disputas por desacordo comercial e erro de processamento, solicitaremos o envio de documentação e/ou evidências no padrão exigido, para comprovar o ocorrido  e que não houve culpa exclusiva de sua empresa, nos termos das regras acima mencionadas.`,
                    },
                    {
                      item: `Crédito em confiança. Uma vez aprovada a abertura da Disputa, o valor da compra contestada será creditado em sua Fatura, conforme as regras de lançamento abaixo. Se tratando de compra parcelada, o valor equivalente à quantia já cobrada será creditado na Fatura, enquanto as demais parcelas ficarão suspensas até o final da Disputa.`,
                      child: [
                        {
                          item: `O crédito será lançado na Fatura fechada, a vencer e ainda não paga por Sua Empresa. Caso o valor a ser creditado seja superior ao total da sua Fatura, a quantia excedente será creditada na Fatura subsequente.`,
                        },
                        {
                          item: `Caso na data de análise da Disputa a Fatura já esteja paga ou vencida, o crédito correspondente à compra contestada será lançado na próxima Fatura aberta. Lembre-se que apenas o não reconhecimento da compra não exime Sua Empresa de efetuar o pagamento integral da Fatura, dessa forma, em caso de atraso ou pagamento parcial, serão devidos os encargos previstos na Cláusula 3.7.`,
                        },
                      ],
                    },
                    {
                      item: `Ao final do processo de Disputa, em caso de aceite do pedido de contestação da compra, o crédito lançado na Fatura será mantido e as parcelas serão suspensas em caráter definitivo. Em contrapartida, caso o estabelecimento apresente contraprovas e o pedido de contestação seja rejeitado, as parcelas da compra objeto da disputa serão reativas e lançadas novamente na fatura da sua empresa.`,
                    },
                    {
                      item: `Sua empresa concorda e aceita que o procedimento de disputa pelo motivo fraude(não reconhecimento e/ou não autorização) somente ocorrerá mediante análise, para compras que sejam efetuadas online ( sem validação de Token). Não serão aceitos procedimentos de disputa por fraude para transações que sejam efetuadas com o uso presencial do cartão no estabelecimento comercial, mediante inserção do cartão e uso de senha ou via contactless (aproximação).`,
                    },
                  ],
                },
                {
                  item: `Caso a data de vencimento da Fatura não seja um dia útil ou coincida com o dia de não funcionamento do Sistema de Pagamentos Brasileiro, o vencimento será estendido para o dia útil imediatamente subsequente. “Dia útil”, para fins deste Contrato, é entendido como os dias que não se encontram na lista de feriados nacionais, de acordo com o calendário disponibilizado pela Federação Brasileira de Bancos (Febraban), disponível no seguinte endereço: <a href="https://feriadosbancarios.febraban.org.br/" target="_blank" alt="Febraban - Feriados federais e bancários">https://feriadosbancarios.febraban.org.br/</a>.`,
                },
              ],
            },
            {
              item: `Pagamento da Fatura, formas e opções de liquidação e de financiamento do saldo devedor da fatura. Mensalmente, Você deverá optar, entre as datas de fechamento e vencimento da Fatura, por:`,
              child: [
                { item: `Pagar o valor total indicado na Fatura; ou` },
                {
                  item: `Pagar qualquer quantia igual ou superior ao Pagamento Mínimo e inferior ao valor integral da Fatura.`,
                },
                {
                  item: `Na hipótese do item 3.6.2, Sua Empresa poderá optar pela contratação de parcelamento do valor restante da Fatura junto à CORA em condições mais vantajosas do que aquelas praticadas na modalidade de “crédito rotativo”, conforme disposto na regulamentação aplicável. Nessa hipótese, o valor pago nos termos do item 3.6.2 acima será considerado como entrada do parcelamento e Você poderá indicar nas Aplicações o número de parcelas em que deseja pagar o valor restante. Sobre o valor total parcelado serão devidos juros e tributos/impostos correspondentes à modalidade “parcelamento”, incluídos em parcelas fixas que vencerão mensalmente no mesmo dia de vencimento da Fatura, juntamente com o valor das transações realizadas com o Cartão durante a vigência da nova Fatura.`,
                },
                {
                  item: `Caso o Usuário realize o pagamento da Fatura na forma descrita no item 3.6.2 e não opte pela contratação de parcelamento do saldo restante da Fatura, esse saldo será automaticamente financiado junto à CORA na modalidade de “crédito rotativo”, havendo incidência de juros remuneratórios e tributos/impostos sobre o saldo financiado. Nesse caso, serão cobrados na próxima Fatura os seguintes valores:
                  <br/>
                  (i) saldo financiado na modalidade “crédito rotativo”;
                  <br/>
                  (ii) juros remuneratórios correspondentes a essa modalidade, incididos sobre o saldo financiado durante o período do financiamento;
                  <br/>
                  (iii) tributos/impostos correspondentes a essa modalidade; e
                  <br/>
                  (iv) valor das transações realizadas com o Cartão durante a vigência da nova Fatura.`,
                },
                {
                  item: `Na hipótese da Cláusula 3.6.4 acima, caso o saldo financiado na modalidade “crédito rotativo” e os respectivos juros remuneratórios incididos não sejam pagos até o vencimento da Fatura seguinte à sua contratação, a soma desses valores será automaticamente financiada na modalidade “parcelamento” mencionada na Cláusula 3.6.3 acima.`,
                },
                {
                  item: `A modalidade “rotativo” e/ou “parcelado” mencionados nas Cláusulas 3.6.3 e 3.6.4 acima poderão ser contratados a partir do fechamento da fatura, desde que seja realizado ao menos o Pagamento Mínimo da Fatura.`,
                },
                {
                  item: `Você deve consultar os Canais de Comunicação da CORA e seguir as instruções de pagamento disponíveis. O não recebimento da Fatura não exclui a obrigação da Sua Empresa de pagamento da Fatura até a data de vencimento. A Fatura sempre estará disponível nas Aplicações, podendo ser acessada a qualquer tempo. Caso tenha problemas de acesso à Fatura, Você deverá entrar em contato com a CORA pelos Canais de Comunicação oficiais.`,
                },
                {
                  item: `O pagamento da Fatura até a data do vencimento será feito através da sua Conta CORA, utilizando o seu saldo em conta corrente.`,
                },
              ],
            },
            {
              item: `Atraso ou falta de pagamento da Fatura. Sua Empresa estará em atraso caso não quite, em qualquer mês, pelo menos o valor do Pagamento Mínimo até a data de vencimento da Fatura. Para realizar o pagamento da Fatura em atraso, Você deve consultar, através das Aplicações ou dos Canais de Comunicação, qual o valor atualizado do saldo devedor.
              <br/>
              Este valor será composto por:
              <br/>
              (i) valor total dos lançamentos em aberto;
              <br/>
              (ii) juros remuneratórios correspondentes aos juros da modalidade “rotativo” e/ou “parcelamento”, conforme saldo aplicável;
              <br/>
              (iii) juros moratórios de 1% ao mês (um por cento);
              <br/>
              (iv) tributos/impostos; e
              <br/>
              (v) multa de 2% do valor fixo da fatura (dois por cento). Caso Você e/ou os Usuários optem por pagar valor inferior ao saldo devedor total atualizado, desde que acima do Pagamento Mínimo, o restante não pago poderá ser financiado na modalidade descrita nas Cláusulas 3.6.3 e 3.6.4.`,
              child: [
                {
                  item: `O atraso no pagamento de qualquer obrigação devida por conta destes Termos de Uso do Cartão ou qualquer outro contrato mantido com a CORA ou empresas do grupo da CORA, incluindo empresas coligadas ou controladas, poderá ocasionar o bloqueio ou o cancelamento do Cartão, independentemente de notificação. Nessa hipótese, Sua Empresa poderá, ainda, ter seu nome inscrito nos órgãos de proteção ao crédito e suas obrigações futuras poderão vencer antecipadamente.`,
                },
                {
                  item: `O Devedor Solidário indicado pela Empresa Selecionada se obriga a efetuar a quitação e respeitar as condições de pagamento presentes nos Termos de Uso do Cartão na qualidade de co-obrigado, solidariamente com a Empresa Selecionada, por todas as obrigações assumidas neste Termos de Uso do Cartão ou que dele decorram, podendo, a qualquer tempo, vir a ser chamado para honrar as obrigações ora assumidas, renunciando a qualquer benefício de ordem e divisão.`,
                },
                {
                  item: `A Empresa Selecionada e os Devedores Solidários desde já reconhecem que os valores das despesas lançadas na Fatura constituem dívida líquida, certa e exigível e que estes Termos de Uso do Cartão, acompanhados do extrato de Conta, bem como os instrumentos representativos dos financiamentos realizados junto a CORA, constituem título executivo extrajudicial.`,
                },
              ],
            },
            {
              item: `Cláusula Mandato. Para que a CORA possa ofertar à Empresa Selecionada a possibilidade de financiamento do saldo da Fatura (nas modalidades “crédito rotativo”, “parcelamento” ou em caso de atraso de pagamento, conforme aplicável), a Empresa Selecionada e os Devedores Solidários desde já nomeiam a CORA sua bastante procuradora, com poderes especiais para, em seu nome e por sua conta, negociar e obter crédito perante a CORA, outorgando-lhe poderes especiais para assinar contratos de financiamento, abrir conta para movimentar os valores financiados, acertar prazos, juros e ônus da dívida, repactuar taxas de juros, emitir títulos de crédito representativos do débito perante a CORA ou, ainda, substabelecer em todo ou em parte os poderes aqui outorgados.`,
            },
            {
              item: `Financiamentos. Os juros dos financiamentos realizados (sejam nas modalidades “crédito rotativo”, “parcelamento” ou em caso de atraso de pagamento, conforme aplicável) serão calculados diariamente sobre o saldo devedor, desde a data de vencimento até a data de seu pagamento, capitalizados mensalmente com base em um fator diário, considerando-se um mês de 30 (trinta) dias. Os juros e tributos/impostos aplicados em cada mês para as hipóteses trazidas nesta cláusula comprometerão o seu Limite de Crédito e deverão ser integralmente pagos na data de vencimento da Fatura. Em caso de ausência de pagamento na data do vencimento, os juros e tributos/impostos serão incorporados ao seu saldo devedor.`,
              child: [
                {
                  item: `As taxas de juros, percentuais de tributos/impostos, e números mínimos e máximos de parcelas aplicáveis a cada operação de financiamento prevista nestes Termos de Uso do Cartão serão informadas previamente na Fatura ou por meio do Aplicativo da Conta CORA.`,
                },
                {
                  item: `O CET das operações de financiamento será informado pela CORA nas Faturas e em outros meios de comunicação colocados à disposição da Sua Empresa, na forma de taxa percentual anual.`,
                },
                {
                  item: `O cálculo do CET de cada operação considerará todos os juros, tributos, tarifas e outras despesas devidas nos termos destes Termos de Uso do Cartão em cada operação, sendo que  para cálculo do CET do financiamento de despesas, informado na Fatura, será considerada a diferença entre o valor do Limite de Crédito utilizado e o valor do Pagamento Mínimo efetuado de cada mês e o prazo de 30 (trinta) dias; `,
                },
              ],
            },
            {
              item: `Autorização para consulta ao SCR e bancos de dados. Você e os Devedores Solidários estão cientes e concordam que a(s) instituição(ões) financeira(s) responsáveis pela concessão de financiamentos à Empresa Selecionada poderá(ão) consultar os débitos e responsabilidades decorrentes de operações com características de crédito e as informações e registros de medidas judiciais que existam em nome da Empresa Selecionada e dos Devedores Solidários junto ao Sistema de Informações de Crédito (SCR), gerido pelo Banco Central do Brasil, ou sistemas que venham a complementá-lo ou a substituí-lo, pois essa consulta é essencial para a análise e concessão de financiamentos no âmbito do Cartão de Crédito CORA.`,
              child: [
                {
                  item: `As instituições mencionadas na Cláusula acima poderão ainda fornecer ao Banco Central do Brasil informações sobre as operações de financiamento contratadas por Sua Empresa, incluindo o montante de suas dívidas a vencer e vencidas, inclusive as em atraso e as operações baixadas com prejuízo.`,
                },
                {
                  item: `Você reconhece que:
                  <br/>
                  (i) o SCR tem por finalidades fornecer informações ao Banco Central do Brasil para fins de supervisão do risco de crédito a que estão expostas as instituições financeiras e propiciar o intercâmbio de informações entre essas instituições com o objetivo de subsidiar decisões de crédito e de negócios;
                  <br/>
                  (ii) o SCR é alimentado mensalmente pelas instituições financeiras, mediante coleta de informações sobre operações concedidas com valor igual ou superior a R$ 200,00 (duzentos reais), vencidas e vincendas, bem como sobre as fianças e avais prestados pelas instituições financeiras a seus clientes;
                  <br/>
                  (iii) as instituições financeiras são obrigadas por regulamentação do Banco Central do Brasil a enviar ao SCR informações das operações de crédito contratadas por Sua Empresa, e que Você poderá ter acesso aos dados constantes nesse sistema por meio do Registrato – Extrato de Registro de Informações do Banco Central do Brasil, um sistema que fornece gratuitamente informações disponíveis em cadastros administrados pelo Banco Central do Brasil;
                  <br/>
                  (iv) as informações remetidas ao SCR são de exclusiva responsabilidade da instituição financeira remetente, sendo certo que solicitações de correções e exclusões e manifestações de discordância quanto às informações constantes no SCR deverão ser dirigidas por Você à instituição financeira remetente; e
                  <br/>
                  (v) mais informações sobre o SCR podem também ser obtidas em consulta ao ambiente virtual do Banco Central do Brasil (<a href="http://www.bcb.gov.br" alt="Banco Central do Brasil" target="_blank">http://www.bcb.gov.br</a>).`,
                },
                {
                  item: `A CORA e as Parceiras poderão acessar dados e informações financeiras relativas à Empresa Selecionada e aos Devedores Solidários junto a serviços de proteção de crédito, incluindo a SERASA – Centralização de Serviços dos Bancos S.A. e quaisquer outros órgãos, bancos de dados, entidades ou empresas que julgar pertinentes, bem como trocar e incluir informações cadastrais, financeiras e de crédito a respeito do Usuário nesses bancos de dados e junto a instituições financeiras e providenciar a abertura de cadastro visando à formação de histórico de crédito da Empresa Selecionada.`,
                },
              ],
            },
          ],
        },
        {
          head: 'Custos relacionados',
          about: 'São informações sobre as Tarifas aplicáveis ao Cartão Cora.',
          intro: '',
          items: [
            {
              item: `Tarifas. A utilização do Cartão de Crédito CORA estará sujeita à cobrança de Tarifas, conforme indicado no Aplicativo da Conta CORA e informações enviadas para o e-mail cadastrado na abertura da Conta CORA.`,
            },
            {
              item: `Alteração de Tarifas. A CORA poderá instituir, alterar ou deixar de cobrar quaisquer Tarifas, a qualquer momento, sempre informando a Sua Empresa com antecedência mínima de 30 (trinta) dias ou de acordo com a regulamentação aplicável. Nesse caso, é facultado à Você cancelar seu cadastro caso não concorde com as alterações realizadas, rescindindo sua relação contratual com a CORA e cessando o uso dos Serviços.`,
            },
            {
              item: `Falta de pagamento de Tarifa. Ocorrendo a falta de pagamento de Tarifa ou qualquer obrigação assumida por Sua Empresa, poderá ser promovido pela CORA e/ou pelas Parceiras o registro do fato junto aos órgãos de proteção ao crédito, conforme pertinente.`,
            },
          ],
        },
        {
          head: 'Cuidados com relação ao Cartão de Crédito CORA',
          about:
            'São Seus deveres ao utilizar o Cartão e o que você deve fazer se algo ocorrer com o Cartão.',
          intro: '',
          items: [
            {
              item: `Falta de pagamento de Tarifa. Ocorrendo a falta de pagamento de Tarifa ou qualquer obrigação assumida por Sua Empresa, poderá ser promovido pela CORA e/ou pelas Parceiras o registro do fato junto aos órgãos de proteção ao crédito, conforme pertinente.`,
            },
            {
              item: `Guarda do Cartão e proteção da Senha do Cartão físico e virtual. Sua Empresa (representada por Você ou outro Usuário, conforme o caso) é a única responsável pelo uso e guarda do seu Cartão físico, não devendo permitir o uso do Cartão ou Senha por terceiros.
              <br/>
              Recomenda-se que:
              <br/>
              (i) o Cartão seja guardado em local seguro e jamais seja permitido seu uso por terceiros;
              <br/>
              (ii) a Senha seja memorizada e mantida em sigilo, evitando-se anotar ou guardar a Senha em suportes físicos ou digitais;
              <br/>
              (iii) a Senha nunca seja anotada ou guardada em conjunto com o Cartão; e
              <br/>
              (iv) a Senha seja forte, não relacionada a datas ou outras referências a aspectos específicos da Sua Empresa, Você ou outro Usuário, e seja trocada periodicamente.
              <br/><br/>
              <b>Atenção:</b> a CORA não solicita ou exige a divulgação de Senhas, dados de Cartão ou outras Informações por qualquer meio diferente dos Canais de Comunicação oficiais. Caso a Sua Empresa receba qualquer comunicação com esse tipo de abordagem e conteúdo, deverá desconsiderar, não responder e, se possível, informá-la em nossos Canais de Comunicação.`,
            },
            {
              item: `Atualizações na Plataforma. As aplicações poderão ser atualizadas a critério da CORA, podendo acontecer, nesse caso, suspensões temporárias nos serviços relacionados ao Cartão de Crédito CORA e à Conta CORA.`,
              child: [
                {
                  item: `Sua Empresa, Você e/ou Usuários se comprometem a sempre utilizar a versão mais atualizada disponível das Aplicações. A CORA poderá suspender o funcionamento de versões desatualizadas das Aplicações, em especial quando as atualizações conferirem melhorias de segurança.`,
                },
                {
                  item: `Sua Empresa será informada pela loja oficial de aplicativos do sistema operacional de seu dispositivo móvel sobre a disponibilidade de novas atualizações (conforme configurações de seu dispositivo móvel) cabendo exclusivamente à Você baixar as versões atualizadas das Aplicações.`,
                },
              ],
            },
            {
              item: `Aplicativos de Terceiros. A integração dos Serviços com contas de pagamento e outros serviços financeiros fornecidos por terceiros (“Aplicativos de Terceiros”) poderá ser disponibilizada pela CORA e/ou pelas Parceiras.`,
              child: [
                {
                  item: `O uso de Aplicativos de Terceiros é de única e exclusiva responsabilidade de Sua Empresa, que se obriga, ainda, a não integrar as Aplicações ou seu Cartão a quaisquer Aplicativos de Terceiros que não sejam autorizados pela CORA, pelas Parceiras e/ou pela Bandeira.`,
                },
                {
                  item: `Os Aplicativos de Terceiros poderão ter seus próprios termos e condições de uso e políticas de privacidade estabelecidos pelo seu proprietário. Sua Empresa compreende e concorda que a CORA, as Parceiras e a Bandeira não endossam e não são responsáveis pelos serviços, recursos ou conteúdo dos Aplicativos de Terceiros ou por qualquer transação que a Sua Empresa possa fazer por meio deles.`,
                },
              ],
            },
            {
              item: `Perda, extravio, furto ou roubo de Cartão. Em caso de perda, extravio, furto ou roubo de um Cartão de Crédito CORA, bem como suspeita de fraude, falsificação ou utilização indevida por terceiros, Você ou outro Usuário deve, imediatamente:`,
              child: [
                {
                  item: `acessar as seções especificamente destinadas ao bloqueio do Cartão disponibilizada pela CORA nas Aplicações e realizar o bloqueio do Cartão; ou`,
                },
                {
                  item: `somente diante da impossibilidade em proceder conforme indicado no item 5.5.1 acima, entrar em contato com a CORA, por meio do telefone indicado nos Canais de Comunicação ou chat eventualmente disponibilizado nas Aplicações, e solicitar o bloqueio do Cartão.`,
                },
                {
                  item: `Após os procedimentos indicados nos itens 5.5.1 ou 5.5.2 acima, o cancelamento do Cartão perdido, extraviado, furtado ou roubado será efetivado pela Cora. Um novo Cartão poderá ser solicitado por meio do aplicativo da Conta CORA, sendo entregue de acordo com os prazos informados pelos Canais de Comunicação e pelo próprio Aplicativo.`,
                },
                {
                  item: `A Cora somente poderá ser responsabilizada pelo uso indevido do Cartão por terceiros nos casos em que não promover o bloqueio.`,
                },
              ],
            },
          ],
        },
        {
          head: `Cancelamento do Cartão`,
          about: `São apresentadas as regras de cancelamento do Cartão.`,
          intro: ``,
          items: [
            {
              item: `Cancelamento a pedido da Sua Empresa.
              <br/>
              Sua Empresa poderá, a qualquer momento, solicitar o cancelamento ou bloqueio do Cartão.`,
            },
            {
              item: `Cancelamento pela CORA.
              <br/>
              A CORA se reserva o direito de, a qualquer momento, cancelar o Cartão de Crédito CORA, mediante comunicação à Sua Empresa, sendo que, no caso de inadimplência da Empresa, o bloqueio poderá ocorrer a partir de 8 (oito) dias contados da identificação do não pagamento da fatura do Cartão.`,
            },
            {
              item: `Cancelamento imediato pela CORA.
              <br/>
              A CORA poderá, ainda, cancelar o Cartão de Crédito CORA imediatamente, mediante envio de notificação à Sua Empresa, nas seguintes hipóteses:`,
              child: [
                {
                  item: `caso Sua Empresa viole quaisquer das disposições destes Termos de Uso do Cartão ou da legislação e regulamentação infralegal pertinente;`,
                },
                {
                  item: `caso a CORA identifique restrições cadastrais ou creditícias em nome de Sua Empresa junto a órgãos de proteção ao crédito;`,
                },
                {
                  item: `caso a CORA verifique que houve deterioração do perfil de risco de crédito da Sua Empresa;`,
                },
                {
                  item: `em caso de má utilização ou uso inadequado do Cartão por Você ou por Sua Empresa;`,
                },
                {
                  item: `no caso de baixa no CNPJ/ME de inscrição da Sua Empresa, ou outras irregularidades apontadas pela Receita Federal do Brasil;`,
                },
                {
                  item: `caso sejam verificadas operações fora do padrão de uso e a Sua Empresa deixe de atender pedido de envio de Informações que comprovem a regularidade dessas operações; e`,
                },
                {
                  item: `em caso de extinção da Sua Empresa, insolvência, falência ou recuperação judicial.`,
                },
              ],
            },
            {
              item: `Responsabilidade pelas obrigações anteriores ao cancelamento.
              <br/>
              Após o cancelamento do Cartão, Sua Empresa permanecerá responsável pelo pagamento dos valores e Tarifas devidos com relação às transações realizadas antes do efetivo cancelamento, sendo permitido à CORA deduzir esses valores do saldo da Conta CORA, se existente, e realizar o envio dos boletos referentes aos valores ainda em aberto na Fatura para o e-mail cadastrado na conta.`,
            },
          ],
        },
        {
          head: `Contato para dúvidas e solicitações`,
          about: `Como podemos nos comunicar.`,
          intro: ``,
          items: [
            {
              item: `Dúvidas e Solicitações. Qualquer dúvida ou solicitação deverá ser sempre encaminhada aos Canais de Comunicação oficiais da CORA e/ou de acordo com a forma descrita nestes Termos de Uso do Cartão ou nos Termos e Condições Gerais de Uso, de modo que qualquer outra forma de comunicação com a CORA e/ou suas Parceiras não será reconhecida.`,
            },
            {
              item: `Comunicações CORA. Todas as comunicações realizadas pela CORA e/ou suas Parceiras com a Empresa Selecionada serão feitas por meio das Aplicações ou das Informações de contato indicadas por Você, sendo da Empresa Selecionada a responsabilidade exclusiva pelo recebimento e visualização dessas comunicações.`,
            },
          ],
        },
        {
          head: `Disposições Gerais sobre o Cartão de Crédito CORA.`,
          about: `São as disposições gerais referentes ao Cartão`,
          intro: ``,
          items: [
            {
              item: `Desbloqueio. Assim que receber o Cartão físico no endereço previamente informado à CORA, Sua Empresa (representada por Você ou outro Usuário cadastrado, conforme o caso) deverá conferir os dados do Cartão e realizar  a ativação o desbloqueio por meio do Aplicativo da Conta CORA.`,
            },
            {
              item: `Formalização. A utilização do Cartão de Crédito CORA é formalizada com o uso da Senha, ou, conforme o caso, por outros meios que caracterizam a expressa manifestação de vontade e concordância de Usuários com a operação em questão, incluindo, sem limitação, pagamentos realizados por meio da tecnologia contactless. Quando a compra for feita de modo online, a formalização deverá ser realizada por meio do uso do código CVV disponibilizado por meio do Aplicativo da Conta Cora. juntamente com o Cartão.`,
            },
            {
              item: `Substituição. A CORA poderá, a seu único e exclusivo critério, substituir o Cartão da  Sua Empresa e/ou seus adicionais por Cartão equivalente ou superior, sendo-lhe facultado, inclusive, alterar a Bandeira do referido Cartão. Sempre que possível, a CORA comunicará referida substituição com antecedência mínima de 30 (trinta) dias.`,
            },
            {
              item: `Restrição preventiva. Fica desde já estabelecido que a CORA, visando à segurança da Sua Empresa, e para evitar o uso fraudulento do Cartão, poderá eventualmente bloquear, restringir ou cancelar o uso do Cartão em caso de movimentações atípicas e uso indevido pelo Usuário.`,
            },
            {
              item: `Estabelecimentos. Sua Empresa compreende e concorda que a CORA não é responsável por eventuais restrições ou condições de pagamento diferenciadas impostas pelos Estabelecimentos ao uso do Cartão ou pela entrega, qualidade, ou quantidade dos bens ou serviços adquiridos pela Sua Empresa por meio do Cartão. Qualquer reclamação relacionada aos produtos ou serviços adquiridos com o Cartão deverá ser direcionada exclusivamente ao Estabelecimento.`,
            },
            {
              item: `Vencimento do Cartão. Você autoriza a CORA a enviar um novo Cartão plástico à Sua Empresa, no endereço cadastrado junto à CORA, sempre que alcançada a data de vencimento do Cartão.`,
            },
          ],
        },
        {
          head: `Informações adicionais`,
          about: `Por fim, trazemos as cláusulas gerais destes Termos de Uso do Cartão.`,
          intro: ``,
          items: [
            {
              item: `Limitação de responsabilidade. A Empresa Selecionada reconhece que todo sistema que depende de rede mundial de computadores, tal como as Aplicações, está sujeito a falhas e indisponibilidade, e que, portanto, não acarretarão na responsabilização da CORA e/ou das Parceiras por eventuais prejuízos causados à Empresa Selecionada.`,
            },
            {
              item: `Cookies. Ao acessar ou navegar pelas plataformas web da CORA você concorda e estará sujeito ao uso de Cookies de empresas terceiras, conforme a Política de Privacidade.`,
            },
            {
              item: `Cessão. A CORA poderá, a qualquer momento e a seu critério, ceder quaisquer de seus direitos e obrigações previstos nestes Termos de Uso do Cartão a qualquer pessoa, mediante simples notificação à Empresa Selecionada, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela CORA.`,
            },
            {
              item: `Alterações nos Termos de Uso do Cartão. Estes Termos de Uso do Cartão podem ser alterados a qualquer tempo, mediante notificação prévia à Empresa Selecionada. A Empresa Selecionada demonstrará que concorda com todas as modificações se continuar a utilizar o Cartão após a alteração dos Termos de Uso do Cartão. Caso não concorde com os novos Termos de Uso do Cartão, a Empresa Selecionada poderá rejeitá-los, realizando o cancelamento do Cartão.`,
            },
            {
              item: `Dados. A Empresa Selecionada  e os Devedores Solidários desde já autorizam a CORA a coletar, tratar e compartilhar os seus dados cadastrais, financeiros e pessoais com as instituições financeiras, pertencentes ou não ao grupo da CORA, para obtenção dos financiamentos. A CORA declara que segue os ditames da Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) no tratamento dos dados do Usuário.`,
            },
            {
              item: `Comunicação ao BACEN. A Empresa Selecionada e os Devedores Solidários desde já declaram estar cientes e de acordo que a CORA e/ou suas Parceiras poderão comunicar ao Banco Central do Brasil, ao Conselho de Controle de Atividades Financeiras (COAF) ou outros órgãos previstos pela legislação, as operações que possam estar configuradas na Lei no 9.613, de 3 de março de 1998, que dispõe sobre os crimes de “lavagem” ou ocultação de bens, direitos e valores, e demais disposições legais pertinentes à matéria, bem como quaisquer operações que possam gerar suspeitas de atos relacionados a fraude, financiamento do terrorismo, ilícitos cambiais ou quaisquer atos ilícitos.`,
            },
            {
              item: `Atos contra a Administração Pública. Ao aderir a estes Termos de Uso do Cartão e contratar o Cartão de Crédito CORA, a Empresa Selecionada e Você obrigam-se a não utilizar o Cartão, de forma direta ou indireta, para a prática de ato previsto na Lei nº 12.846, de 1° de agosto de 2013, que atente contra o patrimônio público nacional ou estrangeiro, contra princípios da administração pública ou contra os compromissos internacionais assumidos pelo Brasil.`,
            },
            {
              item: `Prazo de Vigência. Estes Termos de Uso do Cartão e a Política de Privacidade vigerão por prazo indeterminado, a partir de sua aceitação pela Empresa Selecionada.`,
            },
            {
              item: `Lei Aplicável. Estes Termos de Uso do Cartão e a Política de Privacidade são regidos pelas leis da República Federativa do Brasil.`,
            },
          ],
        },
      ],
    },
  ],
}
